export function isObject(item: any) {
    return (item && typeof item === "object" && !Array.isArray(item));
}


export function structuredMerge(target: any, ...sources: any) {
    if (!sources.length)
        return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key])
                    Object.assign(target, { [key]: {} });
                structuredMerge(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return structuredMerge(target, ...sources);
}
