import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, shareReplay } from "rxjs";
import { icon, Icon } from "@fortawesome/fontawesome-svg-core";
import { faCircleInfo, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { AppSettings, APP_SETTINGS } from "app/app.settings";

//import { Unit } from "./unit.service";
@Injectable({
    providedIn: "root"
})
export class MessageService {
    constructor(@Inject(APP_SETTINGS) private env: AppSettings, private http: HttpClient) { }

    private messagesDataList$: Observable<MessageItem[]> | null = null;

    messageStyleType: MessageStyleType[] = [{ id: 1, icon: icon(faCircleInfo), styleClass: "alert alert-info" }, { id: 2, icon: icon(faCircleInfo), styleClass: "alert alert-primary" }, { id: 3, icon: icon(faCircleInfo), styleClass: "alert alert-secondary" }, { id: 4, icon: icon(faCircleInfo), styleClass: "alert alert-success" }, { id: 5, icon: icon(faTriangleExclamation), styleClass: "alert alert-danger" }, { id: 6, icon: icon(faTriangleExclamation), styleClass: "alert alert-warning" }, { id: 7, icon: icon(faCircleInfo), styleClass: "alert alert-light" }, { id: 8, icon: icon(faCircleInfo), styleClass: "alert alert-dark" }];


    getMessages(justActiveMessages = false): Observable<MessageItem[]> {
        this.messagesDataList$ = this.http
            .get<MessageItem[]>( this.env.apiUrl("messages", { showJustActive: justActiveMessages }))
            .pipe(shareReplay(1));
        this.messagesDataList$.forEach(_ => _.map(data => {
            data.styleClass = this.messageStyleType.filter(m => m.id == data.type).
                flatMap(m => m.styleClass).toString();
            data.styleIcon = this.messageStyleType.filter(me => data.type == me.id)[0].icon;
        }));
        return this.messagesDataList$;
    }
    getMessage(id: number): Observable<MessageItem> {
        return this.http.get<MessageItem>(this.env.apiUrl(`messages/${id}`));
    }

    saveMessage(id: number, message: MessageItem): Observable<any> {
        if (id == 0)
            return this.http.post<MessageItem>(this.env.apiUrl("messages"), message);
        else
            return this.http.put<MessageItem>(this.env.apiUrl(`messages/${id}`), message);
    }
    //TODO: Lägg till units i urlen
    deleteMessage(id: number): Observable<void> {
        return this.http.delete<void>(this.env.apiUrl(`messages/${id}`));
    }
}


export type MessageItem = {
    id: number;
    header?: string;
    description: string;
    link?: string;
    type?: number;
    createdDate?: Date;
    fromDate?: Date | null;
    toDate?: Date | null;
    createdById: string;
    createdByName?: string;
    typeDesc?: string;
    styleClass?: string;
    styleIcon?: Icon | undefined;
    changedByName?: string;
    changedDate: Date;
    changedById: string;
}
//export enum MessageStyleType {
//    Info = 1,
//    Primary = 2,
//    Secondary = 3,
//    Success = 4,
//    Danger = 5,
//    Warning = 6,
//    Light = 7,
//    Dark = 8
//}
export type MessageStyleType = {
    id: number;
    icon?: Icon | undefined;
    styleClass: string;
}


