import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map, Observable, shareReplay } from "rxjs";
import { APP_SETTINGS, AppSettings } from "app/app.settings";


@Injectable({
    providedIn: "root"
})
export class DataService {

    private countyData$: Observable<County[]> | null = null;
    private rollerData$: Observable<Roll[]> | null = null;
    private municipalityData$: Observable<Municipality[]> | null = null;
    private unitData$: Observable<Unit[]> | null = null;
    private infTables = new Map<string, Observable<InfItem[]>>();

    constructor(@Inject(APP_SETTINGS) private env: AppSettings, private http: HttpClient) { }
    getCounties(): Observable<County[]> {
        if (this.countyData$ == null)
            this.countyData$ = this.http
                .get<County[]>(`${this.env.baseHref}api/data/counties`)
                .pipe(
                    shareReplay(1)
                );

        return this.countyData$;
    }
    getMunicipalities(countyCode = 0): Observable<Municipality[]> {
        if (this.municipalityData$ == null)
            this.municipalityData$ = this.http
                .get<Municipality[]>(`${this.env.baseHref}api/data/municipalities`)
                .pipe(
                    shareReplay(1)

                );
        if (countyCode == 0)
            return this.municipalityData$;

        return this.municipalityData$.pipe(map(data => data.filter(m => m.countyCode === countyCode)));
    }

    getInfTable(name: string): Observable<InfItem[]> {
        name = name.toLowerCase();

        if (this.infTables.has(name))
            return this.infTables.get(name)!;

        const infTable = this.http
            .get<InfItem[]>(`${this.env.baseHref}api/data/inf/${name}`)
            .pipe(
                shareReplay(1)
            );

        this.infTables.set(name, infTable);
        return infTable;
    }

    getUserRoles(justPublik: boolean): Observable<Roll[]> {
        //if (this.rollerData$ == null)
            //this.rollerData$ = this.http
            //    .get<Role[]>(`${this.baseHref}api/data/roles/${justPublik}`)
            //    .pipe(
            //        shareReplay(1)
            //    );
        return this.http.get<Roll[]>(`${this.env.baseHref}api/data/roles/${justPublik}`);
    }

    getUnitsByHsa(hsaId = "", showJustUnselectedForUser: boolean, getAllAndCheckUnits: boolean): Observable<Unit[]> {
        return this.http.get<Unit[]>(`${this.env.baseHref}api/data/units/byhsa/${hsaId}?justUnselected=${showJustUnselectedForUser}&getAllAndCheckUnits=${getAllAndCheckUnits}`);
    }

    getUnits(municipalityCode = 0): Observable<Unit[]> {
        if (this.unitData$ == null)
            this.unitData$ = this.http
                .get<Unit[]>(`${this.env.baseHref}api/data/units`)
                .pipe(
                    shareReplay(1)
            );
        if (municipalityCode == 0)
            return this.unitData$;
        //TODO: Filtrera på kommun
        return this.unitData$.pipe(map(data => data.filter(m => m.municipalityCode === municipalityCode)));

    //    return this.unitData$.pipe(map(data => data.filter(m => m. === establishmentCode)));
    }
}

export type County = {
    code: number;
    name: string;
}

export type Municipality = {
    code: number;
    name: string;
    countyCode: number;
}

export type InfItem = {
    id: number;
    description: string;
    sortorder: number | null;
    extra: string | null;
}
export type Roll = {
    id: number;
    namn?: string;
}
export type Unit = {
    id: number;
    code: number;
    name: string;
    municipalityCode: number;
    selected?: boolean;
}
