import { Inject, Component } from "@angular/core";
import { AppSettings, APP_SETTINGS } from "app/app.settings";


@Component({
    selector: "footer[app-footer]",
    templateUrl: "./footer.component.html"
})
export class FooterComponent {
    version = "";

    constructor(@Inject(APP_SETTINGS) private env: AppSettings) {
        this.version = `${env.info.product}\nVersion: ${env.info.version}\nBuild version: ${env.info.fileVersion}\n${env.info.copyright}`;
    }
}
