import { Component } from "@angular/core";
import { UserInfoService } from "@palliativ-core/services/user-info.service";

@Component({
  templateUrl: "./start.component.html",
  styleUrls: ["./start.component.scss"]
})
export class StartComponent {

    constructor(private userInfo: UserInfoService) { }

    login() {
        this.userInfo.authorize();
    }

    signup() {
        window.location.href = "/admin/signup/user";
    }
}
