import { Component, Inject } from "@angular/core";
import { Subscription } from "rxjs";
import { faFlag, faHome, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { OnDestroy } from "@angular/core";
import { Permissions, UserInfoService } from "../../services/user-info.service";
import { AppSettings, APP_SETTINGS } from "app/app.settings";
import { NavigatorService } from "@palliativ-core/services/navigator.service";

@Component({
    selector: "nav[app-nav]",
    templateUrl: "./nav.component.html",
})
export class NavComponent implements OnInit, OnDestroy {
    // Icons
    faUserCircle = faUserCircle;
    faFlag = faFlag;
    faHome = faHome;

    unit = "";
    unitId = 0;
    requestCount = 0;
    changeRequestCount = 0;
    isOpen = false;
    userMenuIsOpen = false;
    roles: [] | undefined;

    canHandleUsers = false;
    canHandlePatients = false;
    canHandleMessages = false;
    canHandleDataExtracts = false;
    canRegisterDeathSurvey = false;
    canRegisterUnitSurvey = false;
    isAdvanced = false;
    isAuthenticated = false;
    hasAdminMenu = false;
    private sub: Subscription | undefined;

    constructor(@Inject(APP_SETTINGS) private env: AppSettings,
        public nav: NavigatorService,
        private userInfoService: UserInfoService) { }

    //////////
    ///
    get filter() {
        return this.searchForm.get("fiter");
    }

    searchForm: FormGroup = new FormGroup({
        filter: new FormControl(""),
    });
    //////////
    ///
    ngOnInit() {
        this.sub = this.userInfoService.userInfoFilter.subscribe(userInfo => {
            this.isAuthenticated = userInfo.isAuthenticated;
            this.canHandleUsers = this.isAuthenticated && userInfo.hasPermission(Permissions.hanteraAnvändare);
            this.canHandlePatients = this.isAuthenticated && userInfo.hasPermission(Permissions.hanteraPatienter);
            this.canHandleMessages = this.isAuthenticated && userInfo.hasPermission(Permissions.hanteraMeddelande);
            this.canHandleDataExtracts = this.isAuthenticated && userInfo.hasPermission(Permissions.hanteraDatauttag);
            this.canRegisterDeathSurvey = this.isAuthenticated && userInfo.hasPermission(Permissions.registreraDödsfall);
            this.canRegisterUnitSurvey = this.isAuthenticated && userInfo.hasPermission(Permissions.registreraEnhetsenkät);
            this.isAdvanced = this.isAuthenticated && userInfo.hasPermission(Permissions.System, Permissions.Support);
            this.hasAdminMenu = this.canHandlePatients || this.canHandleMessages || this.canHandleUsers;
        });
    }

    ngOnDestroy() {
        this.sub?.unsubscribe();
    }

    //////////
    ///
    public isExpanded = false;
    public toggle() {
        this.isExpanded = !this.isExpanded;
    }
    //////////
    ///
    public settingsMenuIsExpanded = false;
    toggleSettingsMenu() {
        this.settingsMenuIsExpanded = !this.settingsMenuIsExpanded;
    }
}
