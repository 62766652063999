<div class="container">
    <div class="row align-items-center">
        <div class="col-6"><a href="https://palliativregistret.se"><img src="assets/img/palliativregistret_logo.svg" width="100" height="100" alt="" class="ml-2"></a></div>
        <div class="col-6 text-end" *ngIf="userId > 0">
            <div>
                <small *ngIf="hasAnyPermissions">Din enhet: <a role="button" style="text-decoration: underline" (click)="nav.navigateTo('MinEnhet')">{{unit}}</a></small>
            </div>
            <div class="mt-1" ngbDropdown>
                <button type="button" ngbDropdownToggle class="btn btn-outline-primary dropdown-toggle">
                    <fa-icon [icon]="faUserCircle"></fa-icon> {{fullName}}
                </button>
                <div ngbDropdownMenu>
                    <a ngbDropdownItem role="button" (click)="changeUnit()">Byt enhet</a>
                    <a ngbDropdownItem role="button" (click)="nav.navigateTo('MinaInställningar')">Mina inställningar</a>
                    <a ngbDropdownItem role="button" (click)="logout()">Logga ut</a>
                </div>
            </div>
            <div class="mt-1" *ngIf="isImpersonating">
                <a href="#" class="btn btn-xsmall btn-warning ms-2" (click)="exitImpersonate()">Sluta agera som {{fullName}}<fa-icon [icon]="faUserGear" class="fa-xs"></fa-icon></a>
            </div>
        </div>
    </div>
</div>
