import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { APP_SETTINGS, AppSettings } from "app/app.settings";

@Injectable({
    providedIn: "root"
})

export class NavigatorService {
    constructor(@Inject(APP_SETTINGS) private env: AppSettings, private router: Router) { }
    
    public navigateTo(link: string, sameWindow = true) {

        link = this.env.links[link];

        if (link[0] == "[" && link[link.length-1] == "]") {
            const p = JSON.parse(link);
            console.log(p);
            this.router.navigate(p);
            return;
        }

        if (sameWindow)
            window.open(link, "_self");
        else
            window.open(link, "_blank");
    }
}
