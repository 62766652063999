import { NgModule, Optional, SkipSelf } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { SharedModule } from "app/shared/shared.module";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    SharedModule
],
  declarations: [
  ],
  exports: [
  ],
  providers: [
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule)
      throw new Error("CoreModule has already been loaded. Import CoreModule modules in the AppModule only.");
  }
}
