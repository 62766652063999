<div class="container">
    <button class="navbar-toggler" type="button" (click)="toggle()">&#9776;</button>
    <div class="collapse navbar-collapse" [class.show]="isExpanded">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item"><a class="nav-link" href="/"><fa-icon [icon]="faHome"></fa-icon></a></li>
            <li *ngIf="canRegisterDeathSurvey" class="nav-item"><a class="nav-link" (click)="nav.navigateTo('Dödsfall')">Dödsfall</a></li>
            <li *ngIf="canRegisterUnitSurvey" class="nav-item"><a class="nav-link" (click)="nav.navigateTo('Enhetsenkät')">Enhetsenkät</a></li>
            <li *ngIf="isAuthenticated" class="nav-item"><a class="nav-link" (click)="nav.navigateTo('Utdata', false)">Utdata</a></li>
            <li ngbDropdown class="nav-item" role="presentation" *ngIf="hasAdminMenu">
                <button type="button" class="nav-link link-light" ngbDropdownToggle>Administration</button>
                <div ngbDropdownMenu>
                    <button *ngIf="canHandleUsers" ngbDropdownItem (click)="nav.navigateTo('AdminAnvändare')">Användare</button>
                    <button *ngIf="canHandleUsers" ngbDropdownItem (click)="nav.navigateTo('AdminEnheter')">Enheter</button>
                    <button *ngIf="canHandleMessages" ngbDropdownItem (click)="nav.navigateTo('AdminAktörer')">Aktörer</button>
                    <button *ngIf="canHandleMessages" ngbDropdownItem (click)="nav.navigateTo('HanteraMeddelande')">Hantera meddelanden</button>
                    <button *ngIf="canHandleUsers&&isAdvanced" ngbDropdownItem (click)="nav.navigateTo('AdminÄndring')">Hantera förfrågningar</button>
                    <button *ngIf="canHandleUsers" ngbDropdownItem (click)="nav.navigateTo('AdminEnhetsändring')">Kommande enhetsändringar</button>
                    <button *ngIf="canHandlePatients" ngbDropdownItem (click)="nav.navigateTo('AdminPatienter')">Hantera patienter</button>
                    <button *ngIf="canHandleDataExtracts" ngbDropdownItem (click)="nav.navigateTo('HanteraDatauttag')">Hantera datauttag</button>
                </div>
            </li>
        </ul>
    </div>
</div>
