<div class="modal-header">
  <h4 class="modal-title pull-left">
    <ng-container *ngIf="id != 0; else newForm">Redigera meddelande</ng-container>
    <ng-template #newForm>Nytt meddelande</ng-template>
  </h4>
  <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<form [formGroup]="editForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <div class="modal-body">
    <div class="form-group row mb-2">
      <label class="col-3 col-form-label" for="header">Rubrik:</label>
      <div class="col-9">
        <input type="text" class="form-control form-control-sm" maxlength="100" formControlName="header" placeholder="Rubrik för meddelandet" (input)="changeHeader()" />
      </div>
    </div>
    <div class="form-group row mb-2">
      <label class="col-3 col-form-label" for="description">Beskrivning: </label>
      <div class="col-9">
        <textarea class="form-control" formControlName="description" rows="5" placeholder="Skriv meddelandet här..." (input)="changeDesc()"></textarea>
      </div>
    </div>
    <div class="form-group row mb-2">
      <label class="col-3 col-form-label" for="link">Länk: </label>
      <div class="col-9">
        <input type="text" class="form-control form-control-sm" formControlName="link" placeholder="http://" (input)="changeLink()" />
        <small class="form-text text-muted">Ange en länkadress som leder till mer information. (Valfritt)</small>
      </div>
    </div>
    <div class="form-group row mb-3">
      <label class="col-3 col-form-label" for="type">Utseendetyp: </label>
      <div class="col-9">
        <select class="form-select form-control-sm" formControlName="type" (change)="changeType()">
          <option value="0">Välj typ</option>
          <option *ngFor="let s of styleTypes | async" [value]="s.id">
            {{ s.description }}
          </option>
        </select>
        <small class="form-text text-muted">Utseendetyp för meddelandet.</small>
      </div>
    </div>
    <div class="form-group row mb-3">
      <label class="col-3 col-form-label" for="fromDate">Visas från: </label>
      <div class="col-9">
        <div class="input-group">
          <input type="text"
                 placeholder="Visas från"
                 class="form-control"
                 formControlName="fromDate" #d="ngbDatepicker" ngbDatepicker (click)="d.toggle()" [minDate]="fromDateminDate" [maxDate]="fromDatemaxDate" (ngModelChange)="fromDateChanged()">
          <button class="btn btn-default btn-sm pt-1" (click)="d.toggle()" type="button"><fa-icon [icon]="faCalendar"></fa-icon></button>
        </div>
        <small class="form-text text-muted">Från när ska meddelandet visas för användarna? (Valfritt) Väljer du inget kommer meddelandet visas direkt.</small>
      </div>
    </div>
    <div class="form-group row mb-3">
      <label class="col-3 col-form-label" for="toDate">Visas till: </label>
      <div class="col-9">
        <div class="input-group">
          <input type="text"
                 placeholder="Visas till"
                 class="form-control"
                 formControlName="toDate" #dt="ngbDatepicker" (click)="dt.toggle()" ngbDatepicker [minDate]="toDateminDate" [maxDate]="toDatemaxDate" (ngModelChange)="toDateChanged()">
          <button class="btn btn-default btn-sm pt-1" (click)="dt.toggle()" type="button"><fa-icon [icon]="faCalendar"></fa-icon></button>
        </div>
        <small class="form-text text-muted">Till när ska meddelandet visas för användarna? (Valfritt) Väljer du inget kommer meddelandet visas tills det tas bort eller redigeras med ett till-datum.</small>
      </div>
    </div>
    <div class="row mb-3 justify-content-end" *ngIf="editForm.value.type > 0">
      <div class="col-4">
        <button type="button" class="btn btn-default btn-sm" (click)="preViewMessage()">Förhandsgranska meddelande <fa-icon [icon]="faChevronDown" *ngIf="!showPreview"></fa-icon><fa-icon [icon]="faChevronUp" *ngIf="showPreview"></fa-icon></button>
      </div>
    </div>
    <div *ngIf="showPreview" class="row mb-3 justify-content-end">
      <label class="col-3 col-form-label" for="toDate">Förhandsgranskning </label>
      <div class="col-9">
        <div [class]="previewMessageItem.styleClass">
          <h4 class="border-bottom">
            <ng-container *ngIf="previewMessageItem.styleIcon"><fa-icon [icon]="previewMessageItem.styleIcon"></fa-icon></ng-container>
            {{previewMessageItem.header}}
          </h4>
          <p>
            {{previewMessageItem.description}}
            <br>
          </p>
          <p class="text-end">
            <small><i>Av {{previewMessageItem.createdByName}}</i></small>
          </p>
          <p class="border-top" *ngIf="previewMessageItem.link != ''">
            <a href="{{previewMessageItem.link}}" target="_blank" class="btn btn-default">Läs mer</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="text-danger" *ngIf="errorMessage!==''">
      <pre style="font-family: inherit">{{errorMessage}}</pre>
    </div>
    <button type="button" class="btn btn-danger" (click)="deleteMessage()" *ngIf="id > 0">Radera meddelande</button>
    <button type="submit" class="btn btn-primary save" [disabled]="!editForm.valid">Spara</button>
  </div>
</form>
