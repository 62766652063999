import { Component } from "@angular/core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "loading-spinner",
    template: `
  <div class="text-center my-4">
    <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
  </div>
  `
})
export class LoadingSpinnerComponent {
    public faSpinner = faSpinner;
}
