import { Component, OnInit } from "@angular/core";
import { UserInfoService } from "../../services/user-info.service";

@Component({
    selector: "app-unauthorized",
    templateUrl: "./unauthorized.component.html"
})
export class UnauthorizedComponent implements OnInit {

    constructor(private userInfoService: UserInfoService) { /**/ }

    ngOnInit() {
        return;
    }

    reset() {
        localStorage.clear();
        try {
            this.userInfoService.logout();
        }
        catch (e) {
            window.location.href = "https://palliativregistret.se";
        }
    }
}
