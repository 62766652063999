import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { CoreModule } from "@core/core.module";
import { PalliativCoreModule } from "@palliativ-core/palliativ-core.module";
import { ManageMessagesComponent } from "./pages/manageMessages/manage-messages.component";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StartComponent } from "./pages/start/start.component";

@NgModule({
  declarations: [
        AppComponent,
        ManageMessagesComponent,
        StartComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ positionClass: "toast-top-center", }),
    SharedModule,
    CoreModule,
    PalliativCoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
