import { InjectionToken } from "@angular/core";
import { OpenIdConfiguration } from "angular-auth-oidc-client";


export class AppSettings {
    public production = false;
    public baseHref = "";
    public links: { [key: string]: string } = {};
    public authConfig: OpenIdConfiguration = {
        autoUserInfo: true,
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        disablePkce: false,
        ignoreNonceAfterRefresh: true
    };
    public info = new ApplicationInfo();

    protected queryParams2String(queryString?: any): string {
        if (!queryString)
            return "";

        let q = "?";
        for (const key in queryString) {
            if (q.length > 1)
                q += "&";
            q += key + "=" + queryString[key];
        }
        return q;
    }

    public apiUrl(uri: string, queryParams?: any): string {
        if (uri.startsWith("/"))
            uri = uri.substring(1);

        return this.baseHref + "api/" + uri + this.queryParams2String(queryParams);
    }
}


export class ApplicationInfo {
    public product = "";
    public version = "";
    public fileVersion = "";
    public copyright = "";
}



export const APP_SETTINGS = new InjectionToken<AppSettings>("appsettings");
