import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-signout",
    templateUrl: "./signout.component.html"
})
export class SignoutComponent implements OnInit {

    ngOnInit() {
        const url = sessionStorage.getItem("signoutRedirect");
        sessionStorage.removeItem("signoutRedirect");
        console.log(`logout url ${url}`);
        window.location.href = url!;
    }

}
