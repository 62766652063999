import { Component, OnInit } from "@angular/core";
import { Permissions, UserInfoService } from "@palliativ-core/services/user-info.service";

@Component({
    selector: "body[app-root]",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

    constructor(private userInfoService: UserInfoService) { }

    ngOnInit() {
        this.userInfoService.authorize();

        this.userInfoService.getUserInfo().subscribe(userInfo => {
            if (userInfo.isAdmin && !userInfo.hasPermission(Permissions.registreraDödsfall, Permissions.registreraEnhetsenkät)) {
                window.location.href = "/admin";
            }
        });
    }
}
