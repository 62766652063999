import { Component } from "@angular/core";
import { Subscription } from "rxjs";
import { faUserCircle, faUserGear } from "@fortawesome/free-solid-svg-icons";
import { OnInit } from "@angular/core";
import { OnDestroy } from "@angular/core";
import { UserInfo, UserInfoService } from "../../services/user-info.service";
import { NavigatorService } from "@palliativ-core/services/navigator.service";

@Component({
    selector: "header[app-header]",
    templateUrl: "./header.component.html"
})
export class HeaderComponent implements OnInit, OnDestroy {
    // Icons
    faUserCircle = faUserCircle;
    faUserGear = faUserGear;

    isAdmin: boolean | undefined;
    isImpersonating: boolean | undefined;

    fullName = "";
    userId = 0;
    unitId = 0;
    unit = "";
    isOpen = false;
    hasAnyPermissions = false;

    private sub: Subscription | undefined;


    // Constructor
    //
    constructor(public nav: NavigatorService, private userInfoService: UserInfoService) { }


    //
    //
    ngOnInit() {
        this.sub = this.userInfoService.userInfoFilter.subscribe((value: UserInfo) => {
            this.fullName = value.name;
            this.userId = value.id;
            this.unit = value.unit;
            this.unitId = value.unitId;
            this.isAdmin = value.isAdmin;
            this.isImpersonating = value.impersonator_id != 0;
            this.hasAnyPermissions = value.permissions != null && value.permissions.length > 0;
        });
    }


    //
    //
    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }


    logout() {
        this.userInfoService.logout();
    }


    changeUnit() {
        this.userInfoService.changeUnit();
    }

    exitImpersonate() {
        this.userInfoService.endImpersonation();
    }
}
