import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { ChangeDetectorRef } from "@angular/core";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";

import { Subscription } from "rxjs";
import { MessageItem, MessageService } from "../../core/services/message.service";
import { EditMessageModalService } from "../home/modals/edit-message-modal/edit-message-modal.component";
import { faEdit } from "@fortawesome/free-solid-svg-icons";


@Component({
    selector: "app-manage-messages",
    templateUrl: "./manage-messages.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush   
})
export class ManageMessagesComponent implements OnInit, OnDestroy  {
    //Ikoner
    faEdit = faEdit;

    @Output() messageDeleted = new EventEmitter<boolean>();
    infoMessages!: Observable<MessageItem[]>;
    constructor(
        private service: MessageService,
        private messageModal: EditMessageModalService
) { }
    //////////
    ///
    ngOnInit(): void {
        this.getData();
    }
    //////////
    ///
    ngOnDestroy(): void {
        this.$subscriptions.forEach(_ => _.unsubscribe());
    }
    //////////
    ///
    getData() {
        this.infoMessages = this.service.getMessages(false);
    }
    //////////
    ///
    $subscriptions: Subscription[] = [];

    openEdit(messageId: number) {
        const modalRef = this.messageModal.show(messageId);
        this.$subscriptions.push(
            modalRef.subscribe(result => {
                this.getData();
            }));
    }

}



