
<div class="container mt-2">
  <h5>Hantera meddelanden</h5>
  <button class="btn btn-primary mb-2" data-toggle="modal" (click)="openEdit(0)">
    Lägg till nytt meddelande
  </button>
  <table class="table w-100 table-striped">
    <thead>
        <tr>
            <th>Typ</th>
            <!--<th>Id</th>-->
            <th>Rubrik</th>
            <th>Från</th>
            <th>Till</th>
            <th>Skapad</th>
            <th>Skapad av</th>
            <th>Ändrad</th>
            <th>Ändrad av</th>
            <th></th>
        </tr>
    </thead>
    <tbody *ngIf="!(infoMessages | async)" class="text-center">
      <tr>
        <td colspan="7">
          <loading-spinner></loading-spinner>
          <i>Laddar meddelanden...</i>
        </td>
      </tr>
    </tbody>
    <ng-container *ngIf="(infoMessages | async) as ucr">
      <tbody *ngIf="ucr.length === 0">
        <tr>
          <td colspan="8" class="text-center"><i>Det finns inga meddelanden</i></td>
        </tr>
      </tbody>
      <tbody *ngIf="ucr.length > 0">
          <tr *ngFor="let message of infoMessages | async" class="p-4 align-middle">
              <td class="align-middle"><fa-icon *ngIf="message.styleIcon" [icon]="message.styleIcon"></fa-icon></td>
              <!--<td class="align-middle">{{message.id}}</td>-->
              <td class="align-middle">{{message.header}}</td>
              <td class="align-middle">{{message.fromDate | date: 'yyyy-MM-dd'}}</td>
              <td class="align-middle">{{message.toDate | date: 'yyyy-MM-dd'}}</td>
              <td class="align-middle">{{message.createdDate | date: 'yyyy-MM-dd'}}</td>
              <td class="align-middle">{{message.createdByName}}</td>
              <td class="align-middle">{{message.changedDate | date: 'yyyy-MM-dd'}}</td>
              <td class="align-middle">{{message.changedByName}}</td>
              <td class="align-middle"><button class="btn btn-sm btn-outline-primary" [ngbTooltip]="'Redigera meddelande'" (click)="openEdit(message.id)"><fa-icon [icon]="faEdit"></fa-icon></button></td>
          </tr>
      </tbody>
    </ng-container>
  </table>
</div>
